@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

* {
  font-family: "Spoqa Han Sans", "Spoqa Han Sans JP", sans-serif;
  box-sizing: border-box;
  color: #323232;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}
